<template>
  <div class="main-container">
    <!-- 导航栏 -->
    <div class="navigation-bar">
      <el-button class="back-btn" icon="el-icon-back" @click="back"
        >返回</el-button
      >
      <el-breadcrumb style="margin-left: 16px" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="/#/riskHomeManagement">风险管理</a></el-breadcrumb-item
        >
        <el-breadcrumb-item>告警列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 筛选 -->
    <div class="filter">
      <!-- tab 1告警记录 2干预记录 -->
      <div class="tabs">
        <div
          :class="['tabs-item', activeTabs === 1 ? 'tabs-item-active' : '']"
          @click="activeTabs = 1"
        >
          告警记录
        </div>
        <div
          :class="['tabs-item', activeTabs === 2 ? 'tabs-item-active' : '']"
          @click="activeTabs = 2"
          style="margin-left: 40px"
        >
          干预记录
        </div>
      </div>
      <div v-if="activeTabs === 1" style="padding: 16px 15px">
        <el-form
          label-width="90px"
          :inline="true"
          :model="searchParams"
          class="demo-form-inline"
          v-if="!isFold"
        >
          <el-form-item label="设备名称">
            <el-input
              v-model="searchParams.deviceName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="告警名称">
            <el-input
              v-model="searchParams.alarmTypeName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="告警类型">
            <el-select
              v-model="searchParams.alarmClassKey"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.alarmTypes"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警时间">
            <el-date-picker
              v-model="dateParams.alarmDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-select
              v-model="searchParams.projectId"
              clearable
              placeholder="请选择"
              @change="changeProject"
            >
              <el-option
                v-for="item in dictList.projectList"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-select
              v-model="searchParams.userId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.customerList"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机械编号">
            <el-input
              v-model="searchParams.frameNo"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select
              v-model="searchParams.deviceTypeKey"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.CXvehicleType"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- ////////////////////////////////////////////////////// -->
      <div v-if="activeTabs === 2" style="padding: 16px 15px">
        <el-form
          label-width="90px"
          :inline="true"
          :model="searchParams2"
          class="demo-form-inline"
          v-if="!isFold"
        >
          <el-form-item label="设备名称">
            <el-input
              v-model="searchParams2.deviceName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属车组">
            <el-input
              v-model="searchParams2.groupName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="干预方式">
            <el-select
              v-model="searchParams2.interveneWayKey"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.interventionTypes"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="处理时间">
            <el-date-picker
              v-model="dateParams.dealDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-select
              v-model="searchParams2.projectId"
              clearable
              placeholder="请选择"
              @change="changeProject"
            >
              <el-option
                v-for="item in dictList.projectList"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-select
              v-model="searchParams2.userId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.customerList2"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机械编号">
            <el-input
              v-model="searchParams2.frameNo"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select
              v-model="searchParams2.deviceTypeKey"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.CXvehicleType"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div style="padding: 16px 15px">
        <el-button @click="getData"> 查询</el-button
        ><el-button @click="resetData"> 重置</el-button>
      </div>
    </div>
    <!-- 告警记录表格 -->
    <div v-if="activeTabs === 1" class="table">
      <div class="table-action">
        <div>
          <el-button @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <div class="flex align-center">
          <!-- <img
              @click="isFold = !isFold"
              style="width: 30px; height: 30px; cursor: pointer"
              src="@/assets/images/RiskControl/cl.png"
            /> -->
          <div @click="handleExportData" class="export">导出</div>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-class-name="headerRowClassName"
        max-height="390"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称">
          ><template slot-scope="{ row }">{{
            row.deviceName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="alarmTypeName" label="告警名称">
          ><template slot-scope="{ row }">{{
            row.alarmTypeName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="alarmClassName" label="告警类型">
          <template slot-scope="{ row }">{{
            row.alarmClassName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="beginDatetime" label="告警开始时间">
          ><template slot-scope="{ row }">{{
            row.beginDatetime || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="endDatetime" label="告警结束时间">
          ><template slot-scope="{ row }">{{
            row.endDatetime || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="duration" label="持续时长（分）">
          ><template slot-scope="{ row }">{{ row.duration || "---" }}</template>
        </el-table-column>
        <el-table-column prop="alarmAddress" label="告警位置">
          ><template slot-scope="{ row }">{{
            row.alarmAddress || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="deviceTypeName" label="设备类型">
          ><template slot-scope="{ row }">{{
            row.deviceTypeName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="vin" label="机械编号">
          ><template slot-scope="{ row }">{{ row.vin || "---" }}</template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称">
          ><template slot-scope="{ row }">{{
            row.projectName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="groupName" label="所属车组">
          ><template slot-scope="{ row }">{{
            row.groupName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称">
          ><template slot-scope="{ row }">{{
            row.customerName || "---"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }"
            ><el-button @click="handleAlarmDetail(row)" type="text"
              >告警详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination">
        <div class="total">共计{{ searchParams.total || 0 }}条记录</div>
        <el-pagination
          background
          layout="sizes,prev,pager,next,jumper"
          :total="searchParams.total"
          :current-page.sync="searchParams.pageIndex"
          :page-size="searchParams.pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 干预记录表格 -->
    <div v-if="activeTabs === 2" class="table">
      <div class="table-action">
        <div>
          <el-button
            @click="getData($event)"
            icon="el-icon-refresh"
          ></el-button>
          <el-button
            @click="interveneDialogVisible = true"
            type="success"
            icon="el-icon-circle-plus-outline"
            >添加</el-button
          >
        </div>
        <div class="flex align-center">
          <!-- <img
              @click="isFold = !isFold"
              style="width: 30px; height: 30px; cursor: pointer"
              src="@/assets/images/RiskControl/cl.png"
            /> -->
          <div @click="handleExportData" class="export">导出</div>
        </div>
      </div>
      <el-table
        :data="tableData2"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-class-name="headerRowClassName"
        max-height="390"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="frameNo" label="机械编号">
          ><template slot-scope="{ row }">{{ row.frameNo || "---" }}</template>
        </el-table-column>
        <el-table-column prop="plate" label="设备名称">
          ><template slot-scope="{ row }">{{ row.plate || "---" }}</template>
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型">
          <template slot-scope="{ row }">{{
            row.deviceType || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称">
          ><template slot-scope="{ row }">{{
            row.projectName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="groupName" label="所属车组">
          ><template slot-scope="{ row }">{{
            row.groupName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="customName" label="客户名称">
          ><template slot-scope="{ row }">{{
            row.customName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="interveneWay" label="干预方式">
          ><template slot-scope="{ row }">{{
            row.interveneWay || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="createUser" label="处理人">
          ><template slot-scope="{ row }">{{
            row.createUser || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="处理时间">
          ><template slot-scope="{ row }">{{
            row.createTime || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          ><template slot-scope="{ row }">{{ row.remark || "---" }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button @click="deleteIntervene(row)" type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination">
        <div class="total">共计{{ searchParams2.total || 0 }}条记录</div>
        <el-pagination
          background
          layout="sizes,prev,pager,next,jumper"
          :total="searchParams2.total"
          :current-page.sync="searchParams2.pageIndex"
          :page-size="searchParams2.pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <AddInterveneDialog
      :show.sync="interveneDialogVisible"
      @submit="getData"
    ></AddInterveneDialog>
    <RiskInterventionDialog
      :show.sync="warnDialogVisible"
      :data="warnDetail"
    ></RiskInterventionDialog>
  </div>
</template>

<script>
import {
  getProjectCustomer,
  getAlarmRecordList,
  getInterventionRecordList,
  exportInterveneData,
  exportAlarmData,
  deleteInterveneData,
} from "@/api/riskData.js";
import { dictionaryBatch } from "@/api/policy";
import { userProject } from "@/api/SystemData";
import dayjs from "dayjs";
import AddInterveneDialog from "../components/AddInterveneDialog.vue";
import RiskInterventionDialog from "../components/RiskInterventionDialog.vue";
import _ from "lodash";
import { alarmTypes, interventionTypes } from "../../js/dictionary.js";
export default {
  components: { AddInterveneDialog, RiskInterventionDialog },
  data() {
    return {
      activeTabs: 1,
      isFold: false,
      dialogVisible: false,
      interveneDialogVisible: false,
      warnDialogVisible: false,
      warnDetail: {},
      dictList: {
        customerList: [],
        customerList2: [],
        dangerTypeList: [],
        projectList: [],
        CXvehicleType: [],
        alarmTypes: alarmTypes,
        interventionTypes: interventionTypes,
      },
      tableData: [],
      tableData2: [],
      dateParams: {
        alarmDate: [],
        dealDate: [],
      },
      searchParams: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        projectId: "",
        userId: "",
        customerName: "",
        deviceTypeKey: "",
        deviceTypeName: "",
        beginDatetime: "",
        endDatetime: "",
        deviceName: "",
        alarmTypeKey: "",
        alarmTypeName: "",
        alarmClassKey: "",
        alarmClassName: "",
        frameNo: "",
      },
      searchParams2: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        interveneWay: "",
        interveneWayKey: "",
        frameNo: "",
        plate: "",
        deviceType: "",
        projectId: "",
        userId: "",
        groupName: "",
        customerName: "",
        beginDatetime: "",
        endDatetime: "",
      },
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.searchParams = { ...this.searchParams, ...this.$route.query };
      this.searchParams2 = { ...this.searchParams2, ...this.$route.query };
      await this.getDictionaryBatch(true);
      this.getData("", true);
    },
    handleExportData() {
      this.handleDatetimerange();
      this.handleKeyToValues();
      if (this.activeTabs == 1) {
        exportAlarmData(this.searchParams);
      } else {
        exportInterveneData(this.searchParams2);
      }
    },
    handleSizeChange(e) {
      this[`searchParams${this.activeTabs == 2 ? 2 : ""}`].pageSize = e;
      this.getData();
    },
    handleCurrentChange(e) {
      this[`searchParams${this.activeTabs == 2 ? 2 : ""}`].pageIndex = e;
      this.getData();
    },
    getData(e, init = false) {
      this.handleDatetimerange();
      this.handleKeyToValues();
      if (init) {
        getAlarmRecordList(this.searchParams).then((res) => {
          this.tableData = res.data.records;
          this.searchParams.total = res.data.total;
        });
        getInterventionRecordList(this.searchParams2).then((res) => {
          this.tableData2 = res.data.records;
          this.searchParams2.total = res.data.total;
        });
      } else {
        if (this.activeTabs == 1) {
          getAlarmRecordList(this.searchParams).then((res) => {
            this.tableData = res.data.records;
            this.searchParams.total = res.data.total;
          });
        } else if (this.activeTabs == 2) {
          getInterventionRecordList(this.searchParams2).then((res) => {
            this.tableData2 = res.data.records;
            this.searchParams2.total = res.data.total;
          });
        }
      }
    },
    async getDictionaryBatch(init = false) {
      dictionaryBatch({
        codes: "CXvehicleType",
      }).then((res) => {
        this.dictList.CXvehicleType = res.data.CXvehicleType || [];
      });
      let proRes = await userProject({
        userId: this.$store.state.userInfo.MJUserId,
      });
      if (proRes.data.length > 0) {
        this.dictList.projectList = proRes.data.map((item) => {
          return {
            dictKey: item.projectId,
            dictValue: item.projectName,
          };
        });
        this.getCustomerList(init);
      }
    },
    getCustomerList(init = false) {
      if (init) {
        this.dictList.customerList = [];
        this.dictList.customerList2 = [];
      }
      this.dictList[`customerList${this.activeTabs == 2 ? 2 : ""}`];
      this[`searchParams${this.activeTabs == 2 ? 2 : ""}`].userId = "";
      getProjectCustomer({
        projectId:
          this[`searchParams${this.activeTabs == 2 ? 2 : ""}`].projectId,
      }).then((res) => {
        if (res.data.records) {
          this.dictList[`customerList${this.activeTabs == 2 ? 2 : ""}`] =
            res.data.records.map((item) => {
              return {
                dictKey: item.customerUserId,
                dictValue: item.customerName,
              };
            });
          if (init) {
            this.dictList.customerList2 = _.cloneDeepWith(
              this.dictList.customerList
            );
          }
        } else {
          this.dictList[`customerList${this.activeTabs == 2 ? 2 : ""}`] = [];
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "zdy-row";
      }
    },
    headerRowClassName() {
      return "zdy-header-row";
    },
    back() {
      this.$router.back();
    },
    resetData() {
      if (this.activeTabs == 1) {
        this.searchParams = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
          projectId: "",
          userId: "",
          customerName: "",
          deviceTypeKey: "",
          deviceTypeName: "",
          beginDatetime: "",
          endDatetime: "",
          deviceName: "",
          alarmTypeKey: "",
          alarmTypeName: "",
          alarmClassKey: "",
          alarmClassName: "",
          frameNo: "",
          ...this.$route.query,
        };
        this.dateParams.alarmDate = [];
      } else if (this.activeTabs == 2) {
        this.searchParams2 = {
          pageIndex: 1,
          pageSize: 10,
          total: 0,
          interveneWay: "",
          interveneWayKey: "",
          frameNo: "",
          plate: "",
          deviceType: "",
          projectId: "",
          userId: "",
          groupName: "",
          customerName: "",
          beginDatetime: "",
          endDatetime: "",
          ...this.$route.query,
        };
        this.dateParams.dealDate = [];
      }
      this.getCustomerList();
      this.getData();
    },
    changeProject(e) {
      //console.log(e);
      this.getCustomerList();
    },
    handleKeyToValues() {
      let kvArry = {
        keyToValues: [
          {
            dic: "alarmTypes",
            key: "alarmClassKey",
            value: "alarmClassName",
          },
          {
            dic: "CXvehicleType",
            key: "deviceTypeKey",
            value: "deviceTypeName",
          },
          {
            dic: "customerList",
            key: "userId",
            value: "customerName",
          },
        ],
        keyToValues2: [
          {
            dic: "interventionTypes",
            key: "interveneWayKey",
            value: "interveneWay",
          },
          {
            dic: "CXvehicleType",
            key: "deviceTypeKey",
            value: "deviceTypeName",
          },
          {
            dic: "customerList2",
            key: "userId",
            value: "customerName",
          },
        ],
      };
      kvArry[`keyToValues${this.activeTabs == 2 ? 2 : ""}`].forEach((item) => {
        if (!this[`searchParams${this.activeTabs == 2 ? 2 : ""}`][item.key])
          return;
        let obj = this.dictList[item.dic].find((kv) => {
          return (
            kv["dictKey"] ==
            this[`searchParams${this.activeTabs == 2 ? 2 : ""}`][item.key]
          );
        });
        if (obj) {
          this[`searchParams${this.activeTabs == 2 ? 2 : ""}`][item.value] =
            obj["dictValue"] || "";
        } else {
          this[`searchParams${this.activeTabs == 2 ? 2 : ""}`][item.value] = "";
        }
      });
    },
    handleDatetimerange() {
      let dateObj = [
        {
          from: "alarmDate",
          to: ["beginDatetime", "endDatetime"],
        },
        {
          from: "dealDate",
          to: ["beginDatetime", "endDatetime"],
        },
      ];
      dateObj.forEach((item) => {
        if (this.dateParams[item.from].length) {
          this[`searchParams${this.activeTabs == 2 ? 2 : ""}`][item.to[0]] =
            this.dateParams[item.from][0]
              ? dayjs(this.dateParams[item.from][0]).format(
                  "YYYY-MM-DD 00:00:00"
                )
              : "";
          this[`searchParams${this.activeTabs == 2 ? 2 : ""}`][item.to[1]] =
            this.dateParams[item.from][1]
              ? dayjs(this.dateParams[item.from][1]).format(
                  "YYYY-MM-DD 23:59:59"
                )
              : "";
        }
      });
    },
    deleteIntervene(item) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteInterveneData({ id: item.id }).then((res) => {
          this.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    handleAlarmDetail(item) {
      this.warnDetail = item;
      this.warnDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  width: 240px;
}
::v-deep .el-input__inner {
  width: 100%;
}
::v-deep .el-input {
  width: 100%;
}
::v-deep .el-select {
  width: 100%;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.main-container {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  height: 100%;
  .navigation-bar {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .filter {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    //padding: 16px 15px;
  }
  .table {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 12px;
    margin-top: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    &-action {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 44px 16px 24px;
      .total {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
.chart {
  width: 100%;
  flex: 1;
}
.export {
  background: #f5f5f5;
  border-radius: 4px 4px 4px 4px;
  padding: 8px 13px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
}
.point {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.back-btn {
  padding: 7px 12px;
  background: #4278c9;
  border-radius: 17px 17px 17px 17px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.tabs {
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 15px;
  .tabs-item {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    padding: 15px 0;
    position: relative;
    cursor: pointer;
  }
  //   激活
  .tabs-item-active {
    color: #4278c9;
    font-weight: bold;
  }
  .tabs-item-active::after {
    content: "";
    display: block;
    width: 56px;
    height: 3px;
    background: #4278c9;
    border-radius: 2px 2px 2px 2px;
    position: absolute;
    bottom: 0;
  }
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
.zdy-header-row {
  background-color: #f2f7fd !important;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
</style>
