<template>
  <div>
    <el-dialog
      title="添加记录"
      destroy-on-close
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="640px"
    >
      <el-form
        label-position="top"
        ref="formData"
        :model="formData"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="机械编号" prop="frameNo" required>
          <el-select
            v-model="formData.frameNo"
            filterable
            remote
            placeholder="请输入关键词"
            :remote-method="querySearchAsync"
            :loading="loading"
          >
            <el-option
              v-for="item in frameNoOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="干预方式" prop="interveneWayKey" required>
          <el-select
            v-model="formData.interveneWayKey"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in interventionTypes"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :rows="5"
            v-model="formData.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addInterveneData, getAllVehicle } from "@/api/riskData.js";
import { interventionTypes } from "../../js/dictionary.js";
export default {
  name: "AddInterveneDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      interventionTypes: interventionTypes,
      formData: {
        frameNo: "",
        interveneWay: "",
        interveneWayKey: "",
        remark: "",
      },
      frameNoOptions: [],
      rules: {
        frameNo: [
          { required: true, message: "请输入机械编号", trigger: "blur" },
        ],
        interveneWayKey: [
          { required: true, message: "请选择干预方式", trigger: "chnage" },
        ],
      },
    };
  },
  watch: {
    show(newValue, oldValue) {
      this.dialogVisible = newValue;
    },
    dialogVisible(n, o) {
      this.$emit("update:show", n);
    },
  },
  methods: {
    handleClose() {
      this.formData = {
        frameNo: "",
        interveneWay: "",
        interveneWayKey: "",
        remark: "",
      };
      this.frameNoOptions = [];
    },
    querySearchAsync(query) {
      if (query !== "") {
        this.loading = true;
        getAllVehicle({ frameNo: query }).then((res) => {
          this.loading = false;
          this.frameNoOptions = res.data.map((item) => {
            return {
              value: item.frameNo,
              label: `${item.frameNo}+${item.plate}`,
            };
          });
        });
      } else {
        this.frameNoOptions = [];
      }
    },
    submitForm() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          let obj = this.interventionTypes.find(
            (item) => item.dictKey == this.formData.interveneWayKey
          );
          if (obj) {
            this.formData.interveneWay = obj.dictValue;
          } else {
            this.formData.interveneWay = "";
          }
          addInterveneData(this.formData).then((res) => {
            this.$emit("update:show", false);
            this.$emit("submit");
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["formData"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  width: 100% !important;
}
::v-deep .el-autocomplete {
  width: 100%;
}
</style>
